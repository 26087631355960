/* --- Seo Number Card Start --- */
.snc-gap{
    row-gap: 30px;
}

.seo-number-card{
    min-height: 256px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    position: relative;
    width: 100%;
    background: linear-gradient(137.45deg, #24262d, var(--dark));
    border-radius: 20px;
}

.snc-bg{
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.snc-bg-1{
    background-image: url("../img/bgimg/banner(02).webp");
}

.seo-number-card > svg{
    width: 28px;
    height: 28px;
    color: var(--gold-light);
}

.seo-number-card .snc-data-bx .snc-number{
    font-size: 40px;
    margin-bottom: 8px;
    font-weight: 600;
    line-height: normal;
}

.seo-number-card .snc-data-bx .snc-text{
    font-size: 14px;
    color: var(--celeste-600);
}

@media screen and (max-width:992px) {
    .seo-number-card .snc-data-bx .snc-number{
        font-size: 32px;
    }
}

@media screen and (max-width:768px) {
    .seo-number-card .snc-data-bx .snc-number{
        font-size: 28px;
    }
}

@media screen and (max-width:576px) {
    .snc-gap{
        row-gap: 20px;
    }
    
    .seo-number-card{
        min-height: inherit;
        padding: 16px;
    }

    .seo-number-card:not(.snc-bg){
        background: none;
        padding: 8px;
    }

    .seo-number-card .snc-data-bx{
        font-size: 24px;
        margin-top: 15px;
    }

    .seo-number-card .snc-data-bx .snc-number{
        font-size: 24px;
    }
}

/* --- Seo Number Card End --- */