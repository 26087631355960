.custom-header{
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 101;
    padding: 0 50px;
    height: 105px;
    -webkit-box-shadow: 0 0px 0 1px transparent;
    -moz-box-shadow: 0 0px 0 1px transparent;
         box-shadow: 0 0px 0 1px transparent;
}

.custom-header.active{
    background-color: var(--dark-600);
    backdrop-filter: blur(10px);
}

.ch-inner{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 100%;
    min-height: 70px;
    padding: 20px 0;
}

.ch-inner .ch-right,
.ch-inner .ch-left{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 160px;
    /* width: 194px; */
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
       -moz-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
}

.ch-inner .ch-left{
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
       -moz-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
}

.ch-inner .ch-right{
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
       -moz-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
}

@media screen and (min-width:992px) {
    .ch-inner .ch-left
    .ch-inner .ch-right{
        width: 160px;
        flex: 0 0 160px;
    }
}


/* --- Logo Css Start --- */
.ch-logo{
    position: relative;
    cursor: pointer;
}

.ch-logo img.logo-img{
    width: 160px;
    display: block;
    position: relative;
    top: -2px;
}

.ch-logo img.icon-anim{
    position: absolute;
    left: 0;
    top: -2px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    animation: logo-anim-rotateY 8s 1s infinite linear;
}

@keyframes logo-anim-rotateY {
    0% {
        transform: rotatey(0deg);
    }
    100%{
        transform: rotatey(360deg);
    }
}

.ch-logo img.icon-img{
    display: none;
    animation: logo-anim-rotateY 8s 1s infinite linear;
}
/* --- Logo Css End --- */

/* --- Menu Links Start --- */
.ch-menu-bx{
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 0 15px;
    width: 100%;
    border: 1px solid var(--dark3);
    border-radius: 15px;
    width: fit-content;
    padding: 10px 10px;
}

.ch-menu-bx .cmb-item{
    position: relative;
    z-index: 2;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    color: var(--celeste-600);
    font-size: 14px;
    white-space: nowrap;
    margin:0 5px;
    padding: 8px 14px 8px;
    -webkit-border-radius: 50px;
       -moz-border-radius: 50px;
            border-radius: 50px;
    font-weight: 500;
    overflow: hidden;
    -webkit-transition:all 0.3s ease-in-out;
    -o-transition:all 0.3s ease-in-out;
    -moz-transition:all 0.3s ease-in-out;
    transition:all 0.3s ease-in-out;
}

.ch-menu-bx .cmb-item svg{
    width: 16px;
    height: 16px;
    margin-left: 5px;
}

.ch-menu-bx .cmb-item .cmb-item-line{
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 1px;
    background-image: -webkit-gradient(linear, right top, left top, from(var(--dark)), color-stop(var(--gold-light)), to(var(--dark)));
    background-image: -webkit-linear-gradient(right, var(--dark), var(--gold-light), var(--dark));
    background-image: -moz-linear-gradient(right, var(--dark), var(--gold-light), var(--dark));
    background-image: -o-linear-gradient(right, var(--dark), var(--gold-light), var(--dark));
    background-image: linear-gradient(-90deg, var(--dark), var(--gold-light), var(--dark));
    z-index: 1;
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.ch-menu-bx .cmb-item .cmb-item-line::before,
.ch-menu-bx .cmb-item .cmb-item-line::after{
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 60%;
    z-index: 1;
}

.ch-menu-bx .cmb-item .cmb-item-line::after{
    left: 0;
    background-image: -webkit-gradient(linear, left top, right top, from(var(--dark)), to(transparent));
    background-image: -webkit-linear-gradient(left, var(--dark), transparent);
    background-image: -moz-linear-gradient(left, var(--dark), transparent);
    background-image: -o-linear-gradient(left, var(--dark), transparent);
    background-image: linear-gradient(90deg, var(--dark), transparent);
}

.ch-menu-bx .cmb-item .cmb-item-line::before{
    right: 0;
    background-image: -webkit-gradient(linear, right top, left top, from(var(--dark)), to(transparent));
    background-image: -webkit-linear-gradient(right, var(--dark), transparent);
    background-image: -moz-linear-gradient(right, var(--dark), transparent);
    background-image: -o-linear-gradient(right, var(--dark), transparent);
    background-image: linear-gradient(-90deg, var(--dark), transparent);
}

.ch-menu-bx .cmb-item:hover{
    color: var(--celeste);
    /* cursor: pointer; */
    background-color: var(--dark2);
}

.ch-menu-bx .cmb-item:hover .cmb-item-line{
    opacity: 1;
}

.cmb-drop-item.active .cmb-item,
.ch-menu-bx .cmb-item.active{
    color: var(--celeste);
    cursor: pointer;
    background-color: var(--dark2);
}

.cmb-drop-item.active .cmb-item .cmb-item-line,
.ch-menu-bx .cmb-item.active .cmb-item-line{
    opacity: 1;
}
/* --- Menu Links End --- */

/* --- --- */
.cmb-drop-item{
    position: relative;
    -webkit-border-radius: 50px;
       -moz-border-radius: 50px;
            border-radius: 50px;
}

.cmb-drop-item .cmb-drop-bx{
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
       -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
         -o-transform: translateX(-50%);
            transform: translateX(-50%);
    z-index: 0;
    padding-top: 60px;
    visibility: hidden;
}

.cmb-drop-item.active .cmb-drop-bx{
    visibility: visible;
}

.cmb-drop-item .cmb-drop-bx .cmb-drop-inner{
    padding: 10px;
    -webkit-border-radius: 12px;
       -moz-border-radius: 12px;
            border-radius: 12px;
    background-clip: padding-box;
    background-color: var(--dark2);
    border: 1px solid var(--dark3);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
       -moz-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(5px);
       -moz-transform: translateY(5px);
        -ms-transform: translateY(5px);
         -o-transform: translateY(5px);
            transform: translateY(5px);
    -webkit-transition:all 0.3s ease-in-out;
    -o-transition:all 0.3s ease-in-out;
    -moz-transition:all 0.3s ease-in-out;
    transition:all 0.3s ease-in-out;
}

.cmb-drop-item.active .cmb-drop-bx .cmb-drop-inner{
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0px);
       -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
         -o-transform: translateY(0px);
            transform: translateY(0px);
}

.cdi-main{
    width: 300px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.cdi-main .cdi-main-item{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    width: calc(50% - 4px);
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 6px 6px 6px;
    -webkit-border-radius: 5px;
       -moz-border-radius: 5px;
            border-radius: 5px;
    font-weight: 500;
    color: var(--celeste-600);
    font-size: 14px;
    margin-top: 0px;
    -webkit-transition: 0.3s ease;
    -o-transition: 0.3s ease;
    -moz-transition: 0.3s ease;
    transition: 0.3s ease;
}

.cdi-main .cdi-main-item img{
    width: 20px;
    height: 20px;
    opacity: 0.4;
    -webkit-transition: 0.3s ease;
    -o-transition: 0.3s ease;
    -moz-transition: 0.3s ease;
    transition: 0.3s ease;
}

.cdi-main .cdi-main-item:first-child{
    margin-top: 0;
}

.cdi-main .cdi-main-item:hover img{
    opacity: 1;
}

.cdi-main .cdi-main-item:hover{
    color: var(--celeste);
    cursor: pointer;
}

.cdi-main .cdi-main-item.active{
    color: var(--celeste);
    cursor: pointer;
}
/* --- --- */

/* --- Aside Menu Icon Start --- */
.menu-icon-bx {
    width: 30px;
    height: 30px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 30px;
       -moz-box-flex: 0;
        -ms-flex: 0 0 30px;
            flex: 0 0 30px;
    display: none;
    -webkit-justify-content: space-around;
        -ms-flex-pack: distribute;
            justify-content: space-around;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 0px;
    cursor: pointer;
    margin-left: 15px;
}

.menu-icon-bx span {
    display: block;
    width: 100%;
    height: 3px;
    -webkit-border-radius: 100px;
       -moz-border-radius: 100px;
            border-radius: 100px;
    opacity: 0.8;
    background-color: var(--celeste);
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.menu-icon-bx span:nth-child(3) {
    width: 60%;
    margin-right: auto;
}

.menu-icon-bx:hover span,
.menu-icon-bx.active span{
    opacity: 1;
}

.menu-icon-bx.active span:nth-child(1) {
    width: 50%;
    margin-left: auto;
}

.menu-icon-bx.active span:nth-child(2) {
    width: 90%;
}

.menu-icon-bx.active span:nth-child(3) {
    width: 50%;
    margin-right: auto;
}
/* --- Aside Menu Icon End --- */


/* --- Signinup Btn Bx Start --- */
.signinup-btn-bx{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
       -moz-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
}

.signinup-btn-bx .sip-btn{
    position: relative;
    color: var(--celeste);
    /* border: 1px solid transparent; */
    padding: 10px 20px 10px;
    -webkit-border-radius: 10px;
       -moz-border-radius: 10px;
            border-radius: 10px;
    font-size: 14px;
    font-weight: 600;
    -webkit-transition:all 0.3s ease-in-out;
    -o-transition:all 0.3s ease-in-out;
    -moz-transition:all 0.3s ease-in-out;
    transition:all 0.3s ease-in-out;
    overflow: hidden;
    white-space: nowrap;
}

.signinup-btn-bx .sip-btn span{
    position: relative;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    height: 100%;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.signinup-btn-bx .sip-btn.register-sip-btn{
    color: var(--dark);
    background-color: var(--gold-light);
    background-image: linear-gradient(90deg, var(--gold-light), var(--gold-dark));
}


.signinup-btn-bx .sip-btn.sip-account-btn{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.signinup-btn-bx .sip-btn img{
    width: 30px;
    height: 30px;
    position: relative;
    top: 0.5px;
    -webkit-border-radius: 50px;
       -moz-border-radius: 50px;
            border-radius: 50px;
    margin-right: 5px;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: center;
       object-position: center;
}


.signinup-btn-bx .sip-btn.register-sip-btn .sbdata,
.signinup-btn-bx .sip-btn.register-sip-btn::before{
    content: attr(sbdata);
    position: absolute;
    inset: 0px;
    color: var(--dark);
    background-color: transparent;
    z-index: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-border-radius: 10px;
       -moz-border-radius: 10px;
            border-radius: 10px;
    -webkit-transform: translateX(-100%);
       -moz-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
         -o-transform: translateX(-100%);
            transform: translateX(-100%);
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.signinup-btn-bx .sip-btn.register-sip-btn::before{
    display: none;
}


/* --- Signinup Btn Bx End --- */


@media only screen and (min-width: 768px) {
    .signinup-btn-bx .sip-btn:hover{
        color: var(--dark);
        background-color: var(--gold-light);
        cursor: pointer;
    }
    
    /* .signinup-btn-bx .sip-btn.register-sip-btn:hover{
        background-color: transparent;
    } */
    
    .signinup-btn-bx .sip-btn.register-sip-btn:hover span{
        -webkit-transform: translateX(-webkit-calc(100% + 20px));
           -moz-transform: translateX(-moz-calc(100% + 20px));
            -ms-transform: translateX(calc(100% + 20px));
             -o-transform: translateX(calc(100% + 20px));
                transform: translateX(calc(100% + 20px));
    }
    
    .signinup-btn-bx .sip-btn.register-sip-btn:hover .sbdata,
    .signinup-btn-bx .sip-btn.register-sip-btn:hover::before{
        -webkit-transform: translateX(0);
           -moz-transform: translateX(0);
            -ms-transform: translateX(0);
             -o-transform: translateX(0);
                transform: translateX(0);
    }

    .lighttheme .custom-header .signinup-btn-bx .sip-btn:not(.register-sip-btn):hover{
        color: var(--white);
        background-color: var(--azure);
    }
}


/* --- @media Css Start --- */
@media screen and (max-width:1250px) {
    .ch-menu-bx{
        /* padding-right: 120px; */
        padding-right: 0;
    }
}

@media screen and (max-width:1024px) {

    .custom-header{
        padding: 0 15px;
    }
    
}

@media screen and (max-width:992px) {
    /* --- @Menu Links Start --- */
    .ch-menu-bx{
        display: none;
    }
    /* --- @Menu Links End --- */

    .ch-inner .ch-right,
    .ch-inner .ch-left{
        width: auto;
    }

    .menu-icon-bx{
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
    }
}

@media screen and (max-width:576px) {

    .ch-logo img.logo-img{
        width: 120px
    }

    .signinup-btn-bx .sip-btn{
        padding: 6px 10px;
    }

    .menu-icon-bx{
        margin-left: 10px;
    }

    /* .signinup-btn-bx .sip-btn:not(.register-sip-btn){
        display: none;
    } */

    .custom-header{
        height: auto;
    }

    .translated-rtl .signinup-btn-bx .sip-btn.register-sip-btn,
    .translated-ltr .signinup-btn-bx .sip-btn.register-sip-btn{
        display: none;
    }
    
}

@media screen and (max-width:392px) {

    .ch-inner{
        padding: 10px 0;
    }

}

@media screen and (max-width:354px) {

    .ch-logo img.icon-anim,
    .ch-logo img.logo-img{
        display: none;
    }

    .ch-logo img.icon-img{
        display: block;
        width: 50px;
        height: 50px;
        position: relative;
        top: 1px;
    }

}
/* --- @media Css End --- */