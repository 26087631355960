/* --- Property Group Img Start --- */
.payment-imgs-bx{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    row-gap: 10px;
}

.payment-img-item{
    position: relative;
    /* cursor: pointer; */
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
       -moz-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.33%;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-attachment: red;
    padding: 5px;
}

.payment-img-item:hover{
    -webkit-filter: grayscale(0) opacity(1);
            filter: grayscale(0) opacity(1);
}

.payment-img-item img{
    width: 100%;
    height: 60px;
    object-position: center;
    object-fit: cover;
}

@media screen and (max-width:576px) {
    .payment-img-item{
        padding: 1px;
    }
}

@media screen and (max-width:360px) {
    .payment-img-item{
        width: 50%;
    }
}
/* --- Property Group Img End --- */