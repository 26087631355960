/* --- Trading Modes Card Start --- */
.tmc-gap{
    row-gap: 30px;
}

.trading-modes-card{
    cursor: pointer;
    position: relative;
    min-height: 256px;
    padding: 25px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    position: relative;
    width: 100%;
    border-radius: 20px;
    border:1px solid var(--dark3);
    overflow: hidden;
}

.trading-modes-card::after{
    content: " ";
    position: absolute;
    left: 0;
    top:0;
    inset: 0;
    z-index: -1;
    opacity: 0;
    background: linear-gradient(137.45deg, #24262d, var(--dark));
    transition: all 0.3s ease-in;
}

.trading-modes-card:hover::after{
    opacity: 1;
}

.trading-modes-card .tmc-top-bx .tmc-icons-bx{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.trading-modes-card .tmc-top-bx .tmc-icons-bx img{
    width: 34px;
    height: 34px;
}

.trading-modes-card .tmc-top-bx .tmc-icons-bx svg{
    width: 22px;
    height: 22px;
    stroke-width: 3px;
    transition: all 0.3s ease-in;
}

.trading-modes-card:hover .tmc-top-bx .tmc-icons-bx svg{
    color: var(--gold-light);
}

.trading-modes-card .tmc-top-bx .tmc-heading{
    font-size: 18px;
    margin-bottom: 8px;
    font-weight: 600;
}

.trading-modes-card .tmc-bottom-bx .tmc-text{
    font-size: 16px;
    color: var(--celeste-800);
}

.trading-modes-img-bx{
    position: relative;
}

.trading-modes-img-bx img{
    width: 100%;
    max-width: 335px;
    max-height: 446px;
    object-position: center;
    object-fit: contain;
}

.trading-modes-hover-img-bx{
    position: absolute;
    left: 8.5%;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: var(--dark);
}

@media screen and (max-width:768px) {
    .trading-modes-card{
        min-height: inherit;    
    }    
}

@media screen and (max-width:576px) {
    .tmc-gap{
        row-gap: 20px;
    }

    .trading-modes-card{
        padding: 16px;
    }

    .trading-modes-card .tmc-top-bx .tmc-heading{
        font-size: 16px; 
    }

    .trading-modes-card .tmc-bottom-bx .tmc-text{
        font-size: 14px; 
    }    
}
/* --- Trading Modes Card End --- */