/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last  1024 versions
*/

/* --- Commun Form Css --- */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.signinup-group select::-webkit-input-placeholder, .signinup-group textarea::-webkit-input-placeholder, .signinup-group input::-webkit-input-placeholder{
    font-weight: 400;
    color: var(--celeste-400);
}

.signinup-group select:-moz-placeholder, .signinup-group textarea:-moz-placeholder, .signinup-group input:-moz-placeholder{
    font-weight: 400;
    color: var(--celeste-400);
}

.signinup-group select::-moz-placeholder, .signinup-group textarea::-moz-placeholder, .signinup-group input::-moz-placeholder{
    font-weight: 400;
    color: var(--celeste-400);
}

.signinup-group select:-ms-input-placeholder, .signinup-group textarea:-ms-input-placeholder, .signinup-group input:-ms-input-placeholder{
    font-weight: 400;
    color: var(--celeste-400);
}

.signinup-group select::-ms-input-placeholder, .signinup-group textarea::-ms-input-placeholder, .signinup-group input::-ms-input-placeholder{
    font-weight: 400;
    color: var(--celeste-400);
}

.signinup-group select::placeholder,
.signinup-group textarea::placeholder,
.signinup-group input::placeholder{
    font-weight: 400;
    color: var(--celeste-400);
}

.signinup-group {
    position: relative;
    margin: 0px 0 20px;
}

.signinup-group select,
.signinup-group textarea,
.signinup-group input {
    font-weight: 400;
    display: block;
    color: var(--white);
    border: 0;
    font-size: 16px;
    background-color: var(--dark);
    border-bottom: 1px solid var(--celeste-400);
    width: 100%;
    outline: none;
    padding: 10px 5px;
    -webkit-border-radius: 0px;
       -moz-border-radius: 0px;
            border-radius: 0px;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.signinup-group select[disabled],
.signinup-group textarea[disabled],
.signinup-group input[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
}

/* .signinup-group select option:first-child {
    font-weight: bold;
    color: red;
    opacity: 0;
} */

.signinup-group.sgli select,
.signinup-group.sgli input{
    padding-left: 50px;
}

.signinup-group.sgri input,
.signinup-group.sgri select{
    padding-right: 40px;
}

.signinup-group input[type="password"] {
    padding: 10px 40px 10px 10px;
}

.signinup-group textarea{
    min-height: 80px;
}

.signinup-group.sg-150-textarea textarea{
    min-height: 150px;
}

.signinup-group .twl-lable{
    display: block;
    text-align: right;
    font-size: 14px;
    margin-top: 5px;
    font-weight: 300;
    color: var(--color4);
}

.signinup-group .group__label {
    font-size: 14px;
    color: var(--celeste-600);
    font-weight: 500;
    margin: 0;
    margin-bottom: -2px;
    margin-left: 5px;
    display: block;
}

.signinup-group .group__label span{
    font-size: 12px;
    color: var(--celeste-600);
    margin-left: 5px;
}

.signinup-group.sgli .group_left_icon{
    position: absolute;
    left: 0px;
    top: 20px;
    height: 45px;
    width: 40px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    pointer-events: none;
}

.signinup-group.sgli .group_left_icon::after{
    content: " ";
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 1px;
    height: 50%;
    background-color: var(--celeste-400);
}

.signinup-group .down-icon{
    border-style: solid;
    border-color: var(--white);
    border-width: 0px 2px 2px 0px;
    display: inline-block;
    padding: 4px;
    -webkit-transform: rotate(45deg);
       -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
         -o-transform: rotate(45deg);
            transform: rotate(45deg);
    position: absolute;
    right: 14px;
    top: 34px;
    pointer-events: none;
}

.signinup-group select:hover,
.signinup-group textarea:hover,
.signinup-group input:hover {
    border-color: var(--celeste);
}

.signinup-group textarea:not(:-moz-placeholder-shown), .signinup-group select:not(:-moz-placeholder-shown), .signinup-group input:not(:-moz-placeholder-shown) {
    border-color: var(--gold-light);
}

.signinup-group textarea:not(:-ms-input-placeholder), .signinup-group select:not(:-ms-input-placeholder), .signinup-group input:not(:-ms-input-placeholder) {
    border-color: var(--gold-light);
}

.signinup-group textarea:focus,
.signinup-group textarea:not(:placeholder-shown),
.signinup-group select:focus,
.signinup-group select:not(:placeholder-shown),
.signinup-group input:focus,
.signinup-group input:not(:placeholder-shown) {
    border-color: var(--gold-light);
}

.signinup-group textarea:not(:-moz-placeholder-shown)~.group__label, .signinup-group select:not(:-moz-placeholder-shown)~.group__label, .signinup-group input:not(:-moz-placeholder-shown)~.group__label {
   color: var(--white);
}

.signinup-group textarea:not(:-ms-input-placeholder)~.group__label, .signinup-group select:not(:-ms-input-placeholder)~.group__label, .signinup-group input:not(:-ms-input-placeholder)~.group__label {
   color: var(--white);
}

.signinup-group textarea:not(:placeholder-shown)~.group__label,
.signinup-group textarea:focus ~ .group__label,
.signinup-group select:not(:placeholder-shown)~.group__label,
.signinup-group select:focus ~ .group__label,
.signinup-group input:not(:placeholder-shown)~.group__label,
.signinup-group input:focus~.group__label {
   color: var(--white);
}

/* --- signinup-group-radio --- */
.signinup-group-radio {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.signinup-group-radio label {
    cursor: pointer;
    position: relative;
    color: var(--color3);
    font-size: 14px;
    font-weight: 500;
    margin: 4px 0;
    margin-right: 8px;
    padding: 5px 16px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-border-radius: 50px;
       -moz-border-radius: 50px;
            border-radius: 50px;
    text-transform: capitalize;
    background-color: var(--color10);
    border: 1px solid var(--color11);
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.signinup-group-radio label:hover {
    background-color: var(--color2-200);
    color: var(--white);
    border-color: var(--color2);
}

.signinup-group-radio input:checked + label {
    border-color: var(--color2);
    background-color: var(--color2);
    color: var(--white);
}

/* --- otp-group --- */
.signinup-group.otp-group{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 5px 0 20px;
}

.signinup-group.otp-group input{
    width: 45px;
    height: 45px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 45px;
       -moz-box-flex: 0;
        -ms-flex: 0 0 45px;
            flex: 0 0 45px;
    margin: 0 5px;
    text-align: center;
    padding: 0;
}

/* --- signinup-group info --- */
.signinup-group-info{
    font-size: 12px;
    color: var(--celeste-600);
    font-weight: 400;
    margin-top: 10px;
}



/* --- Signinup group nots --- */
.signinup-group-nots{
    background: var(--color1-500);
    -webkit-border-radius: 5px;
       -moz-border-radius: 5px;
            border-radius: 5px;
    padding: 10px 15px;
    margin-bottom: 20px;
}

.signinup-group-nots span {
    font-size: 13px;
    color: var(--color3);
    font-weight: 500;
}

.signinup-group-nots p {
    color: var(--color4);
    font-size: 14px;
    margin: 5px 0;
    line-height: 1.5;
}


/* --- clear-input-icon --- */
.fileupload-play,
.clear-input-icon{
    position: absolute;
    right: 0px;
    top: 23px;
    height: 46px;
    width: 40px;
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    padding: 6px;
    cursor: pointer;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
}

.clear-input-icon:hover{
    color: #983A25;
}

.clear-input-icon svg{
    width: 100%;
    height: 100%;
}

/* ---
Error Input color css
--- */
.signinup-group.error-group textarea,
.signinup-group.error-group select,
.signinup-group.error-group input{
    border-color: #983A25;
}

.signinup-group.error-group .group__label{
    color: #983A25 !important;
}

/* Form Error Msg */
.form-error-msg{
    display: block;
    text-align: right;
    color: #983A25;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: -10px;
    margin-top: 5px;
}

.form-error-msg .fa{
    margin-right: 5px;
}

.form-error-msg::before{
    content: "\f071";
    font: normal normal normal 14px/1 FontAwesome;
    margin-right: 5px;
}

/* --- Remember Input Checkbox --- */
.signinup-group-checkmark{
    margin: 10px 0 10px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.signinup-group-checkmark label{
    font-size: 14px;
    color: var(--celeste-600);
    font-weight: 400;
    margin: 0;
    /* margin-left: 8px; */
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
       -moz-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    cursor: pointer;
}

.signinup-group-checkmark label .checkmark-icon{
    position: relative;
    top: 1px;
    width: 20px;
    height: 20px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 20px;
       -moz-box-flex: 0;
        -ms-flex: 0 0 20px;
            flex: 0 0 20px;
    -webkit-border-radius: 5px;
       -moz-border-radius: 5px;
            border-radius: 5px;
    margin-right: 8px;
    background-color: transparent;
    background-position: center;
    border: 2px solid var(--celeste-600);
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    cursor: pointer;
}

.signinup-group-checkmark label:hover .checkmark-icon{
    border-color: var(--gold-light);
}

.signinup-group-checkmark input{
    display: none;
}

.signinup-group-checkmark input:checked + label .checkmark-icon {
    background-image: url(../img/icons/check.svg);
    border-color: var(--gold-light);
    background-color: var(--gold-light);
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: contain;
       -moz-background-size: contain;
         -o-background-size: contain;
            background-size: contain;
    -webkit-box-shadow: none;
       -moz-box-shadow: none;
            box-shadow: none;
}

/* --- Common File Upload Css --- */
.fileupload{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 15px 10px;
    width: 100%;
    min-height: 100px;
    -webkit-border-radius: 16px;
       -moz-border-radius: 16px;
            border-radius: 16px;
    background-color: var(--celeste-100);
    border: 1px dashed var(--celeste-400);
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.fileupload .feud-img{
    width: 50px;
    height: 50px;
    opacity: 0.5;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.fileupload .feud-lable{
    color: var(--celeste);
    font-weight: 500;
    font-size: 14px;
    margin-top: 15px;
    text-align: center;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.fileupload .feud-lable span{
    color: var(--white);
    text-decoration: underline;
}

.fileupload:hover{
    border-style: solid;
    border-color: var(--gold-light);
}

.fileupload:hover .feud-img{
    opacity: 1;
}

.fileupload:hover .feud-lable span{
    color: var(--gold-light);
}

/* --- File Upload List --- */
.dataupload-list,
.fileupload-list{
    display: block;
    padding: 0;
}

.dataupload-list .dataupload-item,
.fileupload-list .fileupload-item{
    position: relative;
    padding: 12px;
    font-size: 16px;
    font-weight: 500;
    margin-top: 15px;
    -webkit-border-radius: 12px;
       -moz-border-radius: 12px;
            border-radius: 12px;
    background-color: var(--celeste-100);
    border: 1px solid var(--celeste-400);
    list-style-position: inside;
    padding-right: 40px;
    padding-left: 40px;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
}

.fileupload-list .fileupload-item img{
    max-width: 150px;
    width: 100%;
    height: 90px;
    -webkit-border-radius: 5px;
       -moz-border-radius: 5px;
            border-radius: 5px;
    -o-object-fit: contain;
       object-fit: contain;
    -o-object-position: center;
       object-position: center;
    background: var(--color1);
    -webkit-box-shadow: 0 0 10px 0px var(--black-700) inset;
       -moz-box-shadow: 0 0 10px 0px var(--black-700) inset;
            box-shadow: 0 0 10px 0px var(--black-700) inset;
}

.fileupload-list .fileupload-item.filstn{
    list-style: none;
}

.dataupload-list .dataupload-item{
    list-style: none;
    padding-right: 40px;
    padding: 6px 12px;
}

.dataupload-list .dataupload-item .dui-item{
    font-size: 17px;
}

.dataupload-list .dataupload-item .dui-item span{
    color: var(--color4);
    font-size: 14px;
    margin-left: 2px;
    font-weight: 500;
}

.fileupload-list .fileupload-item .fileupload-play,
.dataupload-list .dataupload-item .clear-input-icon,
.fileupload-list .fileupload-item .clear-input-icon{
    top: 1px;
}

.fileupload-list .fileupload-item .fileupload-play{
    left: 0px;
}

.fileupload-video-popup{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 111;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 15px;
    background-color: var(--black-700);
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
}

.fileupload-video-popup{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 111;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 15px;
    background-color: var(--black-700);
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
}

.video-wraper-item{
    position: relative;
    width: 100%;
    max-width: 992px;
    height: 100%;
}

.video-wraper-item video{
    position: absolute;
    max-width: 100%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
       -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
         -o-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    -o-object-fit: contain;
       object-fit: contain;
    -o-object-position: center;
       object-position: center;
    max-height: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    -webkit-border-radius: 5px;
       -moz-border-radius: 5px;
            border-radius: 5px;
}



/* --- Input Autofill Css --- */
.signinup-group textarea:-webkit-autofill,
.signinup-group textarea:-webkit-autofill:hover,
.signinup-group textarea:-webkit-autofill:focus,
.signinup-group textarea:-webkit-autofill:active,
.signinup-group select:-webkit-autofill,
.signinup-group select:-webkit-autofill:hover,
.signinup-group select:-webkit-autofill:focus,
.signinup-group select:-webkit-autofill:active,
.signinup-group input:-webkit-autofill,
.signinup-group input:-webkit-autofill:hover,
.signinup-group input:-webkit-autofill:focus,
.signinup-group input:-webkit-autofill:active {
    font-weight: 400 !important;
    font-size: 16px !important;
    caret-color: var(--white) !important;
    font-family: 'Montserrat' !important;
    -webkit-text-fill-color:  var(--white) !important;
    background: var(--dark) !important;
    box-shadow: 0 0 1px 1px var(--dark), 0 0 0 100px var(--dark) inset !important;
    -webkit-box-shadow: 0 0 1px 1px var(--dark), 0 0 0 100px var(--dark) inset !important;
    -webkit-transition: none !important;
    transition: none !important;
}

/* --- Sign In Up Site Info Css --- */
.sign-in-up-bx .site-info {
    font-size: 12px;
    color: var(--color4);
    text-align: justify;
    margin-top: 10px;
    line-height: 1.3;
    font-weight: 400;
}

.sign-in-up-bx .site-info span {
    display: inline-block;
    width: 12px;
}

.sign-in-up-bx .site-info a {
    display: inline-block;
    color: var(--color3);
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.sign-in-up-bx .site-info a:hover {
    color: var(--color2);
}


/* --- login submit btn --- */
.common-submit-btn,
.lsb-loader-btn,
.login-submit-btn{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 10px 15px;
    -webkit-border-radius: 50px;
       -moz-border-radius: 50px;
            border-radius: 50px;
    width: 100%;
    font-weight: 500;
    color: var(--color3);
    font-size: 17px;
    margin: 15px auto;
    position: relative;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: var(--color2);
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    max-width: 450px;
    width: 100%;
}

.common-submit-btn[disabled],
.login-submit-btn[disabled]{
    opacity: 0.7; 
}

.common-submit-btn:hover,
.login-submit-btn:hover{
    color: var(--white);
}

.common-submit-btn:active,
.login-submit-btn:active{
    -webkit-transform: scale(0.95);
       -moz-transform: scale(0.95);
        -ms-transform: scale(0.95);
         -o-transform: scale(0.95);
            transform: scale(0.95);
}

/* --- lsb loader btn --- */
.lsb-loader-btn img{
    pointer-events: none;
    width: 20px;
    height: 20px;
    margin-right: 7px;
    position: relative;
    margin-right: 8px;
    -webkit-animation: rotation-btn 1s infinite linear;
       -moz-animation: rotation-btn 1s infinite linear;
         -o-animation: rotation-btn 1s infinite linear;
            animation: rotation-btn 1s infinite linear;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.lsb-loader-btn img.invert{
    filter: invert(1);
}

@-webkit-keyframes rotation-btn {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@-moz-keyframes rotation-btn {
    from {
        -moz-transform: rotate(0deg);
             transform: rotate(0deg);
    }

    to {
        -moz-transform: rotate(360deg);
             transform: rotate(360deg);
    }
}

@-o-keyframes rotation-btn {
    from {
        -o-transform: rotate(0deg);
           transform: rotate(0deg);
    }

    to {
        -o-transform: rotate(360deg);
           transform: rotate(360deg);
    }
}

@keyframes rotation-btn {
    from {
        -webkit-transform: rotate(0deg);
           -moz-transform: rotate(0deg);
             -o-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
           -moz-transform: rotate(360deg);
             -o-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}


@media (max-width:576px) {
    .signinup-group-nots{
        background: var(--color10);
    }
}


.sg-indicato select{
    -webkit-border-radius: 5px;
       -moz-border-radius: 5px;
            border-radius: 5px;
    padding: 10px 15px;
    color: var(--gold-light);
    border: 1px solid var(--celeste-400);
}

.sg-indicato .down-icon{
    top: 15px;
    border-color: var(--gold-light);
}




.sg-country-code input{
    padding-left: 50px;
}

.sg-country-code .PhoneInputCountry{
    position: absolute;
    top: 33px;
    left: 0;
}

.sg-country-code .PhoneInputCountrySelectArrow{
    padding: 3px;
    border-width: 0 1.5px 1.5px 0;
    position: relative;
    top: -3px;
    left: 3px;
}

.form-white .sg-country-code .PhoneInputCountrySelectArrow{
    border-color: var(--darkblue);
}


.signinup-group-range input{
    -webkit-appearance: none;
    width: -webkit-calc(100%);
    width: -moz-calc(100%);
    width: calc(100%);
    height: 2px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
         border-radius: 50px;
    background-color: var(--celeste);
    outline: none;
    padding: 0;
    margin: 0;
}

.signinup-group-range input::-webkit-slider-thumb {
    -webkit-appearance: none;
            appearance: none;
    width: 100%;
    height: 100%;
    -webkit-border-radius: 50%;
            border-radius: 50%;
    background: red;
    cursor: pointer;
    /* transition: background .15s ease-in-out; */
}


/* --- login-separator --- */
.login-separator{
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-size: 14px;
    font-weight: 600;
    color: var(--celeste);
    margin: 15px 0;
}

.login-separator::after{
    content: "";
    position: absolute;
    width: 100%;
    height: 0.5px;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
    background-color: var(--celeste-300);
}

.login-separator span{
    position: relative;
    z-index: 1;
    display: inline-block;
    padding: 0 10px;
    background-color: var(--darkblue);
}

.signupwhite .login-separator{
    color: var(--darkblue);
}

.signupwhite .login-separator::after{
    background-color: var(--darkblue-100);
}

.signupwhite .login-separator span{
    background-color: var(--white);
}

/* ---
Sign-In-Up Login Commun Btn
--- */

.login-commun-bx{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    gap: 5px;
    margin-bottom: 10px;
    margin-top: -5px;
}

.login-commun-btn {
    width: 45px;
    height: 45px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 5px;
    padding: 5px;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
    font-weight: 600;
    color: var(--celeste-500);
    font-size: 14px;
    margin: 0px 0;
    position: relative;
    border: none;
    outline: none;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.login-commun-btn::after {
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
    background-color: currentColor;
    z-index: 0;
    opacity: 0.1;
    -webkit-transition: -webkit-transform 0.3s ease-in-out;
    transition: -webkit-transform 0.3s ease-in-out;
    -o-transition: -o-transform 0.3s ease-in-out;
    -moz-transition: transform 0.3s ease-in-out, -moz-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out, -moz-transform 0.3s ease-in-out, -o-transform 0.3s ease-in-out;
    -webkit-transform: scale(0);
       -moz-transform: scale(0);
        -ms-transform: scale(0);
         -o-transform: scale(0);
            transform: scale(0);
}

.login-commun-btn svg {
    width: 24px;
    height: 24px;
    fill: currentColor;
    position: relative;
    z-index: 2;
}

.login-commun-btn span {
    display: block;
    position: absolute;
    left: 50%;
    top: 100%;
    -webkit-transform: translateX(-50%) translateY(10px);
       -moz-transform: translateX(-50%) translateY(10px);
        -ms-transform: translateX(-50%) translateY(10px);
         -o-transform: translateX(-50%) translateY(10px);
            transform: translateX(-50%) translateY(10px);
    z-index: 2;
    color: white;
    padding: 2px 8px;
    -webkit-border-radius: 5px;
       -moz-border-radius: 5px;
            border-radius: 5px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    font-weight: 500;
    font-size: 12px;
}

.login-commun-btn span::after {
    content: "";
    position: absolute;
    left: 50%;
    top: -5px;
    width: 10px;
    height: 10px;
    -webkit-transform: translateX(-50%) rotate(-45deg);
       -moz-transform: translateX(-50%) rotate(-45deg);
        -ms-transform: translateX(-50%) rotate(-45deg);
         -o-transform: translateX(-50%) rotate(-45deg);
            transform: translateX(-50%) rotate(-45deg);
    background-color: white;
    z-index: -1;
}

.login-commun-btn:hover span {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateX(-50%) translateY(0px);
       -moz-transform: translateX(-50%) translateY(0px);
        -ms-transform: translateX(-50%) translateY(0px);
         -o-transform: translateX(-50%) translateY(0px);
            transform: translateX(-50%) translateY(0px);
}

.login-commun-btn.facebook-btn span::after,
.login-commun-btn.facebook-btn span {
    background-color: #2D88FF;
}

.login-commun-btn.google-btn span::after,
.login-commun-btn.google-btn span {
    background-color: #ea4436;
}

.login-commun-btn.apple-btn span::after,
.login-commun-btn.apple-btn span {
    background-color: var(--gold-light);
}

.login-commun-btn.li-btn span::after,
.login-commun-btn.li-btn span {
    background-color: #0077b5;
}



.login-commun-btn.facebook-btn:hover {
    color: #2D88FF;
}

.login-commun-btn.google-btn:hover {
    color: #ea4436;
}

.login-commun-btn.apple-btn:hover {
    color: var(--gold-light);
}

.login-commun-btn.li-btn:hover {
    color: #0077b5;
}

.login-commun-btn:hover::after {
    -webkit-transform: scale(1);
       -moz-transform: scale(1);
        -ms-transform: scale(1);
         -o-transform: scale(1);
            transform: scale(1);
}


.signupwhite .login-commun-btn{
    color: var(--darkblue-500);
}



.sg-select-country .css-t3ipsp-control,
.sg-select-country .css-13cymwt-control{
    background-color: transparent;
    border: none;
    -webkit-box-shadow: none;
       -moz-box-shadow: none;
            box-shadow: none;
}

.sg-select-country .css-hlgwow{
    padding: 0 !important;
}

.sg-select-country .css-1fdsijx-ValueContainer{
    position: relative;
    border: none;
    padding: 0;
}

.sg-select-country .css-15lsz6c-indicatorContainer,
.sg-select-country .css-1xc3v61-indicatorContainer{
    position: absolute;
    right: 0;
    top: 0;
    width: 45px;
    height: 45px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.sg-select-country .css-1u9des2-indicatorSeparator{
    display: none;
}


.sg-select-country  .css-166bipr-Input,
.sg-select-country  .css-1dimb5e-singleValue,
.sg-select-country .css-1jqq78o-placeholder{
    border-bottom: 1px solid var(--celeste-400);
    padding: 10px 5px;
    color: var(--celeste-400);
}

.sg-select-country  .css-qbdosj-Input,
.sg-select-country  .css-166bipr-Input{
    color: var(--celeste);
    caret-color: var(--celeste);
}

.sg-select-country  .css-qbdosj-Input input,
.sg-select-country  .css-166bipr-Input input{
    caret-color: var(--celeste);
}

.sg-select-country:hover .css-1jqq78o-placeholder{
    border-bottom-color: var(--celeste);
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.sg-select-country  .css-1dimb5e-singleValue{
    color: var(--celeste);
    border-color: var(--gold-light);
}

.sg-select-country .css-qbdosj-Input{
    margin: 0;
    padding: 10px 5px;
}

.sg-select-country .css-1nmdiq5-menu{
    background-color: var(--darkblue);
    border:1px solid var(--celeste-300)
}


.sg-select-country .css-1nmdiq5-menu > div > div:not(:hover){
    background-color: transparent;
}

.sg-select-country .css-1nmdiq5-menu > div > div:hover{
    /* color: white; */
    background-color: var(--celeste-100) !important;
}